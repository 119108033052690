import { RenbladCardLink } from "./renblad.card.link";
import PropTypes from "prop-types";

const RenbladCardListLink = ({ entries, index = 0 }) => (
  <div className="">
    <div className="o-card-listing">
      {entries.map((entry, ind) => (
        <RenbladCardLink entry={entry} key={entry.title + ind} index={index} />
      ))}
    </div>
  </div>
);

RenbladCardListLink.propTypes = {
  entries: PropTypes.array,
};

export default RenbladCardListLink;
