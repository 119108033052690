import ArrowIcon from "../icons/arrow.icon";
import RenLink from "../shared/RenLink";
import Image from "../media/image";
import PropTypes from "prop-types";
import { prettyDate } from "../../helpers/dates";

const CardFull = ({ index, entry }) => {
  const outerClass =
    "c-card-full" + (index % 2 != 0 ? " u-bg--lightgrey" : " u-bg--white") + " hover-focus";
  return (
    <RenLink href={entry}>
      <div className={outerClass}>
        <div className="c-card-full--flex">
          <section className="c-card-full__top-bar--yellow u-hide--desktop"></section>
          <div className="c-card-full--illustration">
            {entry.illustration && (
              <Image
                className="c-card-full--illustration-image"
                image={entry.illustration}
                alt={entry.illustration.alt}
                interceptBuilder={builder => builder.width(150)}
              />
            )}
          </div>
          <div className="c-card-full--content">
            <div>
              <section className="c-card-full__top-bar--yellow u-hide--mobile"></section>
              {entry._type == "page" && entry.publishDate && (
                <p className="c-card-full__datetag">
                  {prettyDate({ date: entry.publishDate })}
                </p>
              )}
              <h3 className="c-card-full__header">{entry.title}</h3>
              <p>{entry.lede}</p>
            </div>
            <div className="c-card-full__arrow">
              <ArrowIcon />
            </div>
          </div>
        </div>
      </div>
    </RenLink>
  );
};

CardFull.propTypes = {
  index: PropTypes.number,
  entry: PropTypes.object,
};

export default CardFull;
