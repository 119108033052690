import ArrowIcon from "../icons/arrow.icon";
import RenLink from "../shared/RenLink";
import {
  resolveSanityUrl,
  resolveSanityUrlFilepath,
} from "../../helpers/resolveSanityUrl";
import { limitText } from "../../helpers/limitText";

type RenbladCardLinkProps = {
  entry: {
    title: string;
    lede: string;
    seriesNumber: string;
  };
  index: number;
};

export const RenbladCardLink = ({
  entry,
  entry: { title, lede, seriesNumber },
  index,
}: RenbladCardLinkProps) => {
  return (
    <RenLink
      href={entry}
      classes={
        `u-text--black c-renblad-card o-card-listing__item` +
        (index % 2 != 0 ? " u-bg--lightgrey" : " u-bg--white") +
        " hover-focus"
      }
    >
      <div className="c-renblad-card--spacing">
        <div className="c-renblad-card__header">
          <div>Prosjektets mål</div>
        </div>

        <div className="c-renblad-card__content">
          {title && (
            <h3 className=".u-text--black u-roboto500 u-fontLarge">{title}</h3>
          )}

          {lede && (
            <p className="c-renblad-card__lede">{limitText(lede, 35)}</p>
          )}
        </div>

        {resolveSanityUrl(entry) && (
          <div className="c-renblad-card__arrow">
            <ArrowIcon />
          </div>
        )}
      </div>
    </RenLink>
  );
};
